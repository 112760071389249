<template>
  <div class="page">
    <div class="page-title">Контакты</div>

    <div class="content">
      <div class="nav">
        <div
          @click=" setCards({name: '', address: ''})"
          class="nav__item"
          :class="{active: currentDistrict === ''}"
        >
          Общие
        </div>

        <div
          class="nav__item"
          v-for="d in districts"
          :key="d.id"
          @click="setCards(d)"
          :class="{active: currentDistrict === d.name}"
        >
          {{ d.name }}
        </div>
      </div>

      <div class="target-scroll" ref="scrollTarget"/>

      <div class="list">
        <div v-if="searchedAddress" class="point">
          <div class="point__row">
            <img src="@/assets/images/point.svg">
            <span>{{searchedAddress}}</span>
          </div>
          <div class="point__time">
            {{time}}
            <br/>
            {{lunch}}
          </div>
        </div>

        <transition name="quick-hide">
          <div class="default-list" v-if="!currentDistrict && $store.state.commonContactsData">

            <div class="contact-item  show">
              <div class="icon">
                <img src="@/assets/images/contacts-icon-phone.svg"/>
              </div>

              <ECPhones />
            </div>

            <div class="contact-item show">
              <div class="icon">
                <img src="@/assets/images/contacts-icon-home.svg"/>
              </div>
              <div class="description">Центральный офис обслуживания населения</div>
              <div class="info" v-if="$store.state.commonContactsData.office_center_address">{{$store.state.commonContactsData.office_center_address}}</div>
              <div class="separator"/>
              <div class="description">Время работы</div>
              <div class="info" v-if="$store.state.commonContactsData.office_center_days && $store.state.commonContactsData.office_center_lunch"> {{$store.state.commonContactsData.office_center_days}} <br/> {{$store.state.commonContactsData.office_center_lunch}}</div>
            </div>

            <div class="contact-item show">
              <div class="icon">
                <img src="@/assets/images/contacts-icon-mail.svg">
              </div>
              <div class="description">Электронная почта</div>

              <a
                v-if="$store.state.commonContactsData.email_common_1"
                class="info-item"
                :href="`mailto:${$store.state.commonContactsData.email_common_1}`"
              >
                <span>{{$store.state.commonContactsData.email_common_1}}</span>
              </a>
              <a
                v-if="$store.state.commonContactsData.email_common_2"
                class="info-item"
                :href="`mailto:${$store.state.commonContactsData.email_common_2}`"
              >
                <span>{{$store.state.commonContactsData.email_common_2}}</span>
              </a>
              <a
                v-if="$store.state.commonContactsData.email_common_3"
                class="info-item"
                :href="`mailto:${$store.state.commonContactsData.email_common_3}`"
              >
                <span>{{$store.state.commonContactsData.email_common_3}}</span>
              </a>

              <div class="separator"/>
              <div class="description">Для обращений физ. лиц</div>

              <a
                v-if="$store.state.commonContactsData.email_fiz_lico"
                class="info-item"
                href="mailto:abonent-fl@roecocity.ru"
                :href="`mailto:${$store.state.commonContactsData.email_fiz_lico}`"
              >
                <span>{{$store.state.commonContactsData.email_fiz_lico}}</span>
              </a>

              <div class="separator"/>
              <div class="description">Для обращений юр. лиц</div>

              <a
                v-if="$store.state.commonContactsData.email_ur_lico"
                class="info-item"
                href="mailto:abonent-fl@roecocity.ru"
                :href="`mailto:${$store.state.commonContactsData.email_ur_lico}`"
              >
                <span>{{$store.state.commonContactsData.email_ur_lico}}</span>
              </a>

            </div>

            <div class="contact-item  show">
              <div class="icon">
                <img src="@/assets/images/contacts-icon-point.svg"/>
              </div>
              <div class="description">Почтовый адрес</div>
              <div class="info" v-if="$store.state.commonContactsData.address_post">{{$store.state.commonContactsData.address_post}}</div>
              <div class="separator"/>
              <div class="description">Юридический адрес</div>
              <div class="info" v-if="$store.state.commonContactsData.address_ur">{{$store.state.commonContactsData.address_ur}}</div>
            </div>
          </div>
        </transition>

        <transition-group name="quick-hide" class="searched-list" tag="ul">
          <li
            v-if="currentDistrict"
            class="contact-item"
            v-for="card in searchedCards"
            :key="card.id"
          >
            <div v-if="card.avatar" class="avatar" :style="{backgroundImage: 'url(' + card.avatar + ')'  }"></div>
            <div
              v-else
              class="avatar"
              :style="{ backgroundImage: 'url(' + require('@/assets/images/no-img-avatar.svg') + ')' }">
            </div>

            <div class="description position">{{card.position}}</div>
            <div class="info name">{{card.name}}</div>
            <div class="separator"/>
            <div class="description">Телефон</div>
            <a class="info-item masked-phone" :href="`tel:${card.phone}`">
              <span>{{ prettyPhone(handlePhoneToDefault(card.phone))  }}</span>
            </a>
            <div class="separator"/>
            <div class="description">E-mail</div>
            <a class="info-item" :href="`mailto:${card.mail}`">
              <span>{{card.mail}}</span>
            </a>
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import ECPhones from '@/components/common/ECPhones.vue'
import useApi from '@/services/useApi'
import axios from 'axios'
import { maska as vMaska } from 'maska'
import handlePhoneToDefault from '@/helpers/handlePhoneToDefault.js'
import ECPhoneNumber from '@/components/ui/ECPhoneNumber.vue'
import prettyPhone from '@/helpers/prettyPhone.js'


const districts = ref([])
const searchedCards = ref([])
const currentDistrict = ref('')
const searchedAddress = ref('')
const time = ref('')
const lunch = ref('')

const scrollTarget = ref(null)


const setCards = point => {
  currentDistrict.value = point.name
  time.value = point.days
  lunch.value = point.lunch
  searchedAddress.value = point.address
  searchedCards.value = point.managers

  if(window.innerWidth <= 1100){
    window.scrollTo({
      top: scrollTarget.value.offsetTop - 100,
      behavior: "smooth"
    })
  }
}


onMounted(()=> {
  getDistricts()
})

const s = ref([])

const getDistricts = async() => {
  // const req = await axios.get(`${"https://admin.roecocity.ru/api"}/districts?populate=kontakty_sotrudnikis,kontakty_sotrudnikis.avatar&pagination[pageSize]=1000000`)
  const req = await axios.get(`${process.env.VUE_APP_STRAPI_API}/districts?populate=managers,managers.avatar&pagination[pageSize]=1000000`)

  districts.value = req.data.data.map(item => {
    return {
      id: item.id,
      name: item.attributes.name,
      address: item.attributes.address,
      days: item.attributes.days,
      lunch: item.attributes.lunch,
      managers: item.attributes.managers.data.map(item => {
        return {
          id: item.id,
          mail: item.attributes.mail,
          name: item.attributes.name,
          phone: item.attributes.phone ? handlePhoneToDefault(item.attributes.phone) : '-',
          position: item.attributes.position,
          avatar: item.attributes.avatar.data ? `${process.env.VUE_APP_STRAPI_UPLOADS}${item.attributes.avatar.data.attributes.url}` : ''
        }
      })
    }
  })

  districts.value.sort(( a, b ) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
}
</script>

<style scoped lang="scss">
.content{
  display: flex;
  gap: 0 30px;
  align-items: flex-start;
  @media (max-width: 1100px){
    flex-direction: column;
  }
}
.nav{
  padding: 24px;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  @media (max-width: 1100px){
    width: 100%;
    margin-bottom: 50px;
  }
  &__item{
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    transition: ease .3s;
    &:hover{
      color: #57BA5E;
    }
    &.active{
      color: #57BA5E;
    }
  }
}

.contact-item{
  padding: 24px;
  background: #FFFFFF;
  border-radius: 22px;
  width: 350px;
  margin-bottom: 24px;
  @media (max-width: 1100px){
    width: 100%;
  }
  .avatar{
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 16px 16px 0 0;

    height: 350px;
    width: calc(100% + 48px);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 16px 16px 0 0;
    margin-left: -24px;
    margin-top: -24px;
    margin-bottom: 20px;
  }
  .info{
    font-size: 16px;
  }
  .icon{
    width: 48px;
    background: #57BA5E;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .description{
    line-height: 130%;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .info-item{
    font-size: 16px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    span{
      font-size: 16px;
    }
    &.masked-phone{
      input{
        font-size: 16px;
        background: white;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.separator{
  height: 2px;
  width: 100%;
  background: #F3F3F3;
  border-radius: 2px;
  margin: 20px 0;
}


.point{
  margin-bottom: 36px;
  &__row{
    display: flex;
    align-items: center;
    position: relative;
    max-width: 356px;
    margin-left: -6px;
    span{
      margin-left: 16px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__time{
    opacity: .5;
    padding-left: 53px;
    margin-top: 7px;
  }
}

.searched-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .contact-item{
    mix-blend-mode: 24px;
    width: 350px;

    @media (max-width: 1100px){
      width: 100%;
    }
  }
}
</style>
